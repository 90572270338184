<template>
  <div>
    <b-row>
      <b-col md="9">
        <div class="ml-2 pb-2">
          <h3 class="text-bold">
            Создать Года
          </h3>
          <h5 class="pt-3">
            Название
          </h5>
          <input
            id="fname"
            type="text"
            name="fname"
            size="40"
          >
        </div>
        <div class="col-md-5">
          <b-form-datepicker
            v-model="value"
            :min="min"
            :max="max"
            locale="en"
            size="md"
          />
        </div>
        <div class="pt-4 text-center">
          <b-button
            class="px-4 mr-5"
            variant="danger"
          >
            Вернутся
          </b-button>
          <b-button
            class="px-4"
            variant="primary"
          >
            Добавить
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'YearAdd',
}
</script>

<style scoped>

</style>
